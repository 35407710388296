.content {
  position: relative;

  > .leftCover {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 30px;
    // background: blue;
    background: linear-gradient(to right, rgba(20, 20, 20, 1), rgba(20, 20, 20, 0));
  }

  > .rightCover {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 30px;
    background: linear-gradient(to left, rgba(20, 20, 20, 1), rgba(20, 20, 20, 0));
  }
}

