@fontFamily: CrimsonText-Italic;

.content {
  background: var(--foreground-rgb);
  margin-top: 50px;
  padding: 30px 0px;

  > div:nth-of-type(1) {
    text-align: center;
    font-weight: bold;
    font-size: 42px;
    color: var(--app-text-color);
    margin-bottom: 50px;
    font-family: @fontFamily;
  }

  .container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    > .cardContainer {
      display: inline-block;
      min-width: 480px;
      max-width: 720px;
      height: 260px;
      border-radius: 10px;
      overflow: hidden;
      margin-right: 64px;
      background: #272429;

      &:last-child {
        margin-right: 0;
      }

      > .contentInfo {
        display: flex;

        > .leftContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          min-width: 160px;

          > .img {
            height: 260px;
            width: 210px;
          }
        }

        > .rightContainer {
          padding: 0 40px;
          width: 100%;

          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          > .userContainer {
            margin-bottom: 5px;
            display: flex;
            align-items: center;

            > span {
              margin-left: 10px;
              font-weight: 400;
              font-size: 20px;
              color: var(--app-text-color);
            }
          }

          .title {
            margin-top: 16px;
            font-weight: bold;
            font-size: 18px;
            color: var(--app-text-color);
          }

          .desc {
            margin-top: 10px;
            font-weight: 400;
            font-size: 13px;
            text-wrap: balance;
            line-height: 20px;
            color: var(--app-text-60-color);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .content {
    padding: 0;
  }
}

